<template>
  <div class="container-fluid mt-3 mb-3">
    <CRow :class="isMobile ? '' : 'pl-3 pr-3'">
      <CCol sm="6">
        <h4 class="heading-title">Assign Meter</h4>
      </CCol>
      <CCol sm="6" class="text-right">
        <router-link class="btn btn-sm btn-primary" to="/customer-accounts"
          >Back</router-link
        >
      </CCol>

      <CCol sm="12" class="mt-4">
        <button
          v-if="!assignMeter"
          type="button"
          class="btn btn-primary btn-lg"
          @click="assignMeter = 'QR'"
        >
          Scan QR code
        </button>
        &nbsp;
        <button
          v-if="!assignMeter"
          type="button"
          class="btn btn-info btn-lg ml-4"
          @click="assignMeter = 'Manual'"
        >
          Assign Manually
        </button>

        <AssignMeterManually
          :meters="meters"
          :customerAccId="customerAccId"
          v-if="assignMeter === 'Manual'"
        />
        <QrScanAndAssignMeter
          :meters="meters"
          :customerAccId="customerAccId"
          v-if="assignMeter === 'QR'"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AssignMeterManually from "@/components/customer_accounts/partials/AssignMeterManually";
import QrScanAndAssignMeter from "@/components/customer_accounts/partials/QrScanAndAssignMeter";

export default {
  name: "AssignMeterToCustomerAcc",
  components: {
    AssignMeterManually,
    QrScanAndAssignMeter,
  },
  data: () => {
    return {
      isMobile: Boolean,
      isMobileOnly: Boolean,
      customers: [],
      customerId: String,
      serverErrors: [],
      showDismissibleAlert: false,
      meters: [],
      assignMeter: ""      
    };
  },
  computed: {},
  methods: {
    getMeters() {
      this.isLoading = true;
      this.axios
        .get(`/admin/meters/available`)
        .then((res) => {
          this.meters = res.data.data;          
        })
        .catch((e) => {
          console.log(e);          
        });
    },
  },
  mounted() {
    this.customerAccId = this.$route.params.id;
    this.getMeters();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.custom-width {
  width: 100% !important;
}
</style>


<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
}
</style>